<!--
 * @Description: 筛选表单
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="formLayout">
      <FilterLayout @reset="reset" @search="search">
        <a-form-model-item prop="status" label="状态">
          <a-select allowClear v-model="form.status" placeholder="全部">
            <a-select-option
              v-for="value in FILTER_STATUS_LIST"
              :key="value"
              :value="value"
            >{{ REPORT_STATUS_ENUM[value] }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="workOrderCode" label="工单ID">
          <a-input v-model="form.workOrderCode" placeholder="请输入工单ID"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="projectCode" label="项目ID">
          <a-input v-model="form.projectCode" placeholder="请输入项目ID"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="projectName" label="项目名称">
          <a-input v-model="form.projectName" placeholder="请输入项目名称"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
import filterFormMixin from '@/mixins/filterFormMixin'
import { FILTER_STATUS_LIST, REPORT_STATUS_ENUM } from '@/const/ReportStatus'

const FORM = () => ({
  status: undefined,
  workOrderCode: '',
  projectCode: '',
  projectName: '',
  startAndEnd: [undefined, undefined]
})

export default {
  name: 'ReportList',
  mixins: [filterFormMixin],
  data() {
    return {
      FILTER_STATUS_LIST,
      REPORT_STATUS_ENUM,
      form: FORM()
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
