var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "table_area_wrapper" },
    [
      _c(
        "div",
        { staticClass: "custom_vxe_table_wrapper" },
        [
          _c(
            "vxe-table",
            { attrs: { data: _vm.tableData, loading: _vm.loading } },
            [
              _c("vxe-column", {
                attrs: {
                  title: "工单ID",
                  field: "workOrderCode",
                  width: "180px",
                },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "项目ID",
                  field: "projectCode",
                  width: "180px",
                },
              }),
              _c("vxe-column", {
                attrs: { title: "项目名称", field: "projectName" },
              }),
              _c("vxe-column", {
                attrs: { title: "项目描述", field: "description" },
              }),
              _c("vxe-column", {
                attrs: { title: "创建时间", field: "createTime" },
              }),
              _c("vxe-column", {
                attrs: { title: "状态", field: "statusName", width: "160px" },
              }),
              _c("vxe-column", {
                attrs: { title: "操作", field: "action", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "a-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName:
                                  "v-auth:ORGANIZATION_WORKORDERPROCESS_BUILDINGDETAILS",
                                arg: "ORGANIZATION_WORKORDERPROCESS_BUILDINGDETAILS",
                              },
                            ],
                            attrs: { type: "link" },
                            on: {
                              click: function () {
                                return _vm.handleShowDetail(row)
                              },
                            },
                          },
                          [_vm._v("楼栋详情")]
                        ),
                        _c(
                          "a-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName:
                                  "v-auth:ORGANIZATION_WORKORDERPROCESS_REQUEST_ANALYSIS",
                                arg: "ORGANIZATION_WORKORDERPROCESS_REQUEST_ANALYSIS",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowRequestAnalyseBtn(row),
                                expression: "isShowRequestAnalyseBtn(row)",
                              },
                            ],
                            attrs: { type: "link" },
                            on: {
                              click: function () {
                                return _vm.handleRequestAnalyze(row)
                              },
                            },
                          },
                          [_vm._v("请求分析")]
                        ),
                        _c(
                          "a-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowAnalyzeDetailBtn(row),
                                expression: "isShowAnalyzeDetailBtn(row)",
                              },
                              {
                                name: "auth",
                                rawName:
                                  "v-auth:ORGANIZATION_WORKORDERPROCESS_ANALYSISDETAIL",
                                arg: "ORGANIZATION_WORKORDERPROCESS_ANALYSISDETAIL",
                              },
                            ],
                            attrs: { type: "link" },
                            on: {
                              click: function () {
                                return _vm.handleShowAnalyzeDetail(row)
                              },
                            },
                          },
                          [_vm._v("分析详情")]
                        ),
                        _c(
                          "a-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName:
                                  "v-auth:ORGANIZATION_WORKORDERPROCESS_VIEW_REPORT",
                                arg: "ORGANIZATION_WORKORDERPROCESS_VIEW_REPORT",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowViewReportBtn(row),
                                expression: "isShowViewReportBtn(row)",
                              },
                            ],
                            attrs: { type: "link" },
                            on: {
                              click: function () {
                                return _vm.handleViewReport(row)
                              },
                            },
                          },
                          [_vm._v("查看报告")]
                        ),
                        _c(
                          "a-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName:
                                  "v-auth:ORGANIZATION_WORKORDERPROCESS_REASONS_REJECTION",
                                arg: "ORGANIZATION_WORKORDERPROCESS_REASONS_REJECTION",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowRejectReasonBtn(row),
                                expression: "isShowRejectReasonBtn(row)",
                              },
                            ],
                            attrs: { type: "link" },
                            on: {
                              click: function () {
                                return _vm.handleShowRejectReason(row)
                              },
                            },
                          },
                          [_vm._v("驳回原因")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Pagination", {
        attrs: { pagination: _vm.pagination },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
          change: _vm.fetchData,
        },
      }),
      _c("RejectReasonDialog", { ref: "RejectReasonDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }