/*
 * @Description: 报告工单状态
 */

const REPORT_STATUS_ENUM = {
  DETECTING: '检测中',
  DETECTED: '已完成检测',
  UPLOADED: '已上传检测数据',
  AVAILABLE_CONFIRMING: '数据可用性确认中',
  AVAILABLE_REJECTED: '数据可用性已驳回',
  // 报告的状态
  REPORTING: '报告生成中',
  REPORT_REJECTED: '报告生成中',
  REPORTED: '报告生成中',
  // 以下三种在检测机构中是一种状态
  'REPORTING,REPORT_REJECTED,REPORTED': '报告生成中',
  COMPLETE: '报告已完成',
}

// 列表过滤时使用的状态
const FILTER_STATUS_LIST = [
  'DETECTING',
  'DETECTED',
  'UPLOADED',
  'AVAILABLE_CONFIRMING',
  'AVAILABLE_REJECTED',
  'REPORTING,REPORT_REJECTED,REPORTED',
  'COMPLETE'
]

export { FILTER_STATUS_LIST, REPORT_STATUS_ENUM }

export default REPORT_STATUS_ENUM
