<!--
 * @Description: 工单列表
-->

<template>
  <page-header-wrapper>
    <div class="report_list_wrapper page_content">
      <FilterForm ref="FilterForm" @search="updateTable"></FilterForm>
      <div class="mt-10"></div>
      <MainTable ref="MainTable" class="main_table" :getFilter="getFilter"></MainTable>
    </div>
  </page-header-wrapper>
</template>

<script>
import FilterForm from './components/FilterForm.vue'
import MainTable from './components/MainTable.vue'

export default {
  name: 'ProjectList',
  components: {
    FilterForm,
    MainTable
  },
  data() {
    return {}
  },
  methods: {
    getFilter() {
      return this.$refs.FilterForm.form
    },
    updateTable() {
      this.$refs.MainTable.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.report_list_wrapper {
  height: calc(100vh - 190px);
  flex-direction: column;
  display: flex;
}

.main_table {
  flex: 1;
}
</style>
