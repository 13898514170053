<!--
 * @Description: 列表
-->
<template>
  <a-card class="table_area_wrapper">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->
    <div class="custom_vxe_table_wrapper">
      <vxe-table :data="tableData" :loading="loading">
        <vxe-column title="工单ID" field="workOrderCode" width="180px"></vxe-column>
        <vxe-column title="项目ID" field="projectCode" width="180px"></vxe-column>
        <vxe-column title="项目名称" field="projectName"></vxe-column>
        <vxe-column title="项目描述" field="description"></vxe-column>
        <vxe-column title="创建时间" field="createTime"></vxe-column>
        <vxe-column title="状态" field="statusName" width="160px"></vxe-column>
        <vxe-column title="操作" field="action" align="right">
          <template #default="{ row }">
            <a-button
              type="link"
              v-auth:ORGANIZATION_WORKORDERPROCESS_BUILDINGDETAILS
              @click="() => handleShowDetail(row)"
            >楼栋详情</a-button>
            <a-button
              type="link"
              v-auth:ORGANIZATION_WORKORDERPROCESS_REQUEST_ANALYSIS
              v-show="isShowRequestAnalyseBtn(row)"
              @click="() => handleRequestAnalyze(row)"
            >请求分析</a-button>
            <a-button
              type="link"
              v-show="isShowAnalyzeDetailBtn(row)"
              v-auth:ORGANIZATION_WORKORDERPROCESS_ANALYSISDETAIL
              @click="() => handleShowAnalyzeDetail(row)"
            >分析详情</a-button>
            <a-button
              type="link"
              v-auth:ORGANIZATION_WORKORDERPROCESS_VIEW_REPORT
              v-show="isShowViewReportBtn(row)"
              @click="() => handleViewReport(row)"
            >查看报告</a-button>
            <a-button
              type="link"
              v-auth:ORGANIZATION_WORKORDERPROCESS_REASONS_REJECTION
              v-show="isShowRejectReasonBtn(row)"
              @click="() => handleShowRejectReason(row)"
            >驳回原因</a-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!-- 分页 -->
    <Pagination :pagination.sync="pagination" @change="fetchData" />

    <RejectReasonDialog ref="RejectReasonDialog"></RejectReasonDialog>
  </a-card>
</template>

<script>
import ReportController from '@/views/report/api/report'
import { getFilterParams } from '@/utils/utils.custom'
import RejectReasonDialog from './RejectReasonDialog.vue'

export default {
  components: {
    RejectReasonDialog
  },
  props: {
    getFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      currentRow: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 拉取数据
    async fetchData() {
      const params = getFilterParams.call(this)

      try {
        this.loading = true
        const { data, total } = await ReportController.getList(params)
        this.pagination.total = total
        this.tableData = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // 是否显示请求分析按钮
    isShowRequestAnalyseBtn(row) {
      return row.status === 'UPLOADED'
    },
    // 显示分析详情按钮
    isShowAnalyzeDetailBtn(row) {
      return [
        'AVAILABLE_CONFIRMING',
        'AVAILABLE_REJECTED',
        'REPORTING',
        'REPORT_REJECTED',
        'REPORTED',
        'COMPLETE'
      ].includes(row.status)
    },
    // 是否显示查看报告按钮
    isShowViewReportBtn(row) {
      return row.status === 'COMPLETE'
    },
    // 是否显示驳回详情
    isShowRejectReasonBtn(row) {
      return ['AVAILABLE_REJECTED', 'REPORT_REJECTED'].includes(row.status)
    },
    // 楼栋详情
    handleShowDetail(row) {
      this.$router.push({
        path: `/report/project-detail/${row.id}?status=${row.status}`
      })
    },
    // 请求分析
    handleRequestAnalyze(row) {
      this.$router.push(`/report/submit-application/${row.id}`)
    },
    // 查看分析详情
    handleShowAnalyzeDetail(row) {
      this.$router.push(`/report/report-analysis-detail/${row.id}`)
    },
    // 查看报告
    handleViewReport(row) {
      this.$router.push(`/report/view-report/${row.id}`)
    },
    // 查看驳回原因
    handleShowRejectReason(row) {
      this.$refs.RejectReasonDialog.handleShow(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.table_area_wrapper {
  ::v-deep .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.table_area_wrapper {
  .custom_vxe_table_wrapper {
    flex: 1;
  }
}
.offer_wrapper {
  text-align: right;
  padding-right: 1em;
}
</style>
