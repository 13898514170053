var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "form_wrapper" },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "form", attrs: { model: _vm.form } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "FilterLayout",
            { on: { reset: _vm.reset, search: _vm.search } },
            [
              _c(
                "a-form-model-item",
                { attrs: { prop: "status", label: "状态" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { allowClear: "", placeholder: "全部" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.FILTER_STATUS_LIST, function (value) {
                      return _c(
                        "a-select-option",
                        { key: value, attrs: { value: value } },
                        [_vm._v(_vm._s(_vm.REPORT_STATUS_ENUM[value]))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { prop: "workOrderCode", label: "工单ID" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入工单ID" },
                    model: {
                      value: _vm.form.workOrderCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "workOrderCode", $$v)
                      },
                      expression: "form.workOrderCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { prop: "projectCode", label: "项目ID" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入项目ID" },
                    model: {
                      value: _vm.form.projectCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectCode", $$v)
                      },
                      expression: "form.projectCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { prop: "projectName", label: "项目名称" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入项目名称" },
                    model: {
                      value: _vm.form.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectName", $$v)
                      },
                      expression: "form.projectName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { prop: "startAndEnd", label: "创建时间" } },
                [
                  _c("a-range-picker", {
                    model: {
                      value: _vm.form.startAndEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startAndEnd", $$v)
                      },
                      expression: "form.startAndEnd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }